const EVALUATOR_URL = 'https://rateplating.online:8401/predict'
const IMAGE_SIZE = 224;
// TODO: Decide how many files are allowed with one request
const MAX_FILES_ALLOWED = 30;

async function evaluateOnServer(image){
  let formData = new FormData();
  formData.append("image", image);

  try {
    return fetch(EVALUATOR_URL, {method: "POST", body: formData}).then(function(response){
      console.log('HTTP response code: ', response.status);
      return response.json();
    }).then(function(json){
      console.log(json);
      return json;
    });
  } catch(e) {
    console.log("Couldn't fetch data from server. Problem:", e);
  }
}

async function processFileImage(file, imgElem) {
  let json = await evaluateOnServer(file);
  let score;
  if (json['success']){
    score = json['mean_score']
  }
  else {
    // TODO: Handle this case correctly
    window.alert("Try uploading a different image with a food plate.")
    return null;
  }
  showResults(imgElem, score);
}


function showResults(imgElement, score) {
  const predictionContainer = document.createElement('div');
  predictionContainer.className = 'pred-container wow zoomIn';
  predictionContainer.setAttribute('data-wow-delay', "0.1s");

  const imgContainer = document.createElement('div');
  imgContainer.appendChild(imgElement);
  predictionContainer.appendChild(imgContainer);

  const scoreRow = document.createElement('div');
  scoreRow.className = 'score-row';
  const scoreLabel = document.createElement('div');
  scoreLabel.className = 'cell score-label';
  scoreLabel.innerText = 'Score: ';
  scoreRow.appendChild(scoreLabel);

  const scoreValue = document.createElement('div');
  scoreValue.className = 'cell score-value';
  if (score) {
    scoreValue.innerText = score.toFixed(2);
  } else {
    scoreValue.innerText = "Couldn't find a food plate";
  }
  scoreRow.appendChild(scoreValue);

  const starOuter = document.createElement('div');
  starOuter.className = 'stars-outer';
  const starInner = document.createElement('div');
  starInner.className = 'stars-inner';

  if (score) {
    const scorePercentage = (score / 5) * 100
    starInner.style.width = `${Math.round(scorePercentage)}%`;
  } else {
    starInner.style.width = '0%';
  }

  starOuter.appendChild(starInner);
  scoreRow.appendChild(starOuter);
  predictionContainer.appendChild(scoreRow);

  const predictionsElement = document.getElementById('predictions');
  predictionsElement.insertBefore(
      predictionContainer, predictionsElement.firstChild);

}

const filesElement = document.getElementById('files');
filesElement.addEventListener('change', evt => {
  let files = evt.target.files;

  if (files.length > MAX_FILES_ALLOWED) {
    window.alert("Too many files selected! Please upload no more than " + MAX_FILES_ALLOWED);
    return;
  }

  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    if (!file.type.match('image.*')) {
      window.alert("File is not an image!");
      continue;
    }

    //TODO: Don't allow files of a big size (e.g. > 4 Mb or so)

    let reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = e => {
      // Fill the image & call processFileImage()
      let imgElem = document.createElement('img');
      imgElem.src = e.target.result;
      imgElem.width = IMAGE_SIZE;
      imgElem.height = IMAGE_SIZE;
      imgElem.onload = () => processFileImage(file, imgElem);
    };

    // Read in the image file as a data URL.
    reader.readAsDataURL(file);
  }
});
